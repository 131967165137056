import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
import cities from 'modules/project/config/cities.json'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.plan', route: { name: 'plan' } },
      ],
      listRoute: () => ({ name: 'plan' }),
      afterCreateRoute: result => ({
        name: 'plan-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.plan',
      defaultData: this._defaultData,
      blocks: [
        {
          title: 'form_block.base',
          data: {
            name: {
              required: true,
              copyable: true,
              label: 'data.name',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            code: {
              required: true,
              copyable: true,
              label: 'data.code',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            city: {
              label: 'data.city',
              type: 'selection',
              optionTextKey: 'city',
              optionValueKey: 'city',
              options: (formInfo: FormInfoInterface) => cities,
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return !window.eagleLodash.isEqual(originalData, data)
              },
            },
            district: {
              label: 'data.district',
              type: 'selection',
              optionTextKey: 'name',
              optionValueKey: 'name',
              options: (formInfo: FormInfoInterface) => {
                if(!formInfo.formData) return []
                const city = formInfo.formData.city
                const cityObject = cities.find(item => item.city === city)
                if(!cityObject) return []
                if(!Array.isArray(cityObject.districts)) return []
                return cityObject.districts
              },
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return !window.eagleLodash.isEqual(originalData, data)
              },
            },
            photo: {
              label: 'plan.data.photo',
              type: 'photo',
              photoType: 'plan',
              photoManager: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            daily_performance_of_location: {
              label: 'plan.data.daily_performance_of_location',
              help: 'plan.data.daily_performance_of_location.help',
              type: 'text',
              inputType: 'number',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            capacity: {
              label: 'plan.data.capacity',
              type: 'readonly-content',
              text: (data: any, formInfo: FormInfoInterface) => {
                const formData = formInfo.formData
                if(!formData) return 0
                const stages = formData.stages
                if(!Array.isArray(stages)) return 0
                const totalKwp = window.eagleLodash.sumBy(stages, 'total_kwp')
                return this.vm?.$helper.getCapacityHumans(totalKwp)
              },
            },
            yearly_income: {
              label: 'plan.data.yearly_income',
              type: 'readonly-content',
              text: (data: any, formInfo: FormInfoInterface) => {
                const formData = formInfo.formData
                if(!formData) return 0
                const stages = formData.stages
                if(!Array.isArray(stages)) return 0
                const yearlyInCome = window.eagleLodash.sumBy(stages, 'yearly_income')
                return `${this.vm?.$helper.number(yearlyInCome)} (約${this.vm?.$helper.numberH(yearlyInCome)})`
              },
            },
          },
        },

        // 發布設定
        {
          title: 'plan.data.stage',
          data: {
            stages: {
              grid: { xl: 12, lg: 12, md: 12},
              component: () => import('./planForm/stages.vue'),
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return !window.eagleLodash.isEqual(originalData, data)
              },
            }
          },
        },

        // 發布設定
        {
          title: 'form_block.publish',
          data: {
            status: {
              label: 'data.status',
              type: 'switch',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
              grid: { xl: 12, lg: 12, md: 12},
            },
            start_at: {
              label: 'data.start_at',
              type: 'time',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
            end_at: {
              label: 'data.end_at',
              type: 'time',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],

      customDataAction: {
        preview: {
          label: 'action.preview',
          icon: 'fa fa-eye',
          buttonProperties: () => ({ class: 'white--text' }),
          href: (formInfo: FormInfoInterface) => `${this.vm?.frontendDomain}/plan/${formInfo.formData?.code}`,
          linkTarget: '_blank',
        }
      },
    }
  }
}

export default new formConfig()
